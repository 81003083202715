import { http } from 'connex-cds';

export const postNewDeviceConfig = async (entityRef, data, deviceData) => {
  const resp = http
    .post({ apiName: 'assurance', path: `/${entityRef}/device-configs`, data })
    .then(function (response) {
      const vehicleDeviceRef = deviceData?.crn;
      const deviceConfigRefs = deviceData?.deviceConfigRefs;
      const newConfigRef = {
        deviceConfigRef: response?.crn,
        entityRef: entityRef,
        default: false,
        id: response?.id,
      };

      if (deviceConfigRefs[response?.deviceType]) {
        deviceConfigRefs[response?.deviceType].push(newConfigRef);
      } else {
        deviceConfigRefs[response?.deviceType] = [newConfigRef];
      }

      http.patch({
        apiName: 'assurance',
        path: `/${entityRef}/vehicle-devices/${vehicleDeviceRef}`,
        data: { deviceConfigRefs },
      });
    })
    .catch(function (error) {
      console.log(error);
    });
};
