import { Autocomplete, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListDeviceGroups, useListVehicleDevices } from '../../../../query-hooks/assurance';
import { dynamicSort } from '../../utils/dynamicSort';
import { Core } from 'connex-cds';
import { GMSelectedCard } from '../../../../cds-labs/GMSelectedCard';
import moment from 'moment';

export const StepThree = ({ entityRef, setNextBtnDisabled, setVehicles, getData = null }) => {
  const groups = useListDeviceGroups(entityRef);
  const vehicles = useListVehicleDevices(entityRef);
  const [inputValue, setInputValue] = useState('');
  const [inputOptions, setInputOptions] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [selectedTrucks, setSelectedTrucks] = useState([]);
  const initialIcon = 'add_circle';
  const initialDisplay = 'block';
  const [doRefetch, setdoRefetch] = useState(getData);

  const iconStyle = {
    fontSize: '50px',
  };

  useEffect(() => {
    if (doRefetch) {
      vehicles.refetch();
      setdoRefetch(false);
    }
  }, [doRefetch, getData]);

  useEffect(() => {
    if (!groups.isFetching && !groups.isError) {
      const options = [];

      groups.data.forEach(group => {
        options.push({
          label: group.id,
          id: group.crn,
        });
      });

      // Sorting the commands with a custom function
      const sortedGroups = options.sort(dynamicSort('label'));

      setInputOptions([...sortedGroups]);
    }
  }, [groups.data, groups.isError, groups.isFetching, inputOptions]);

  useEffect(() => {
    if (vehicles?.data && vehicles?.data.length > 0) {
      vehicles?.data.forEach(truck => {
        const checkIn = truck?.lastCheckinDateTime
          ? moment(truck.lastCheckinDateTime).format('DD/MM/YYYY hh:mm LT')
          : '-- : --';

        truck.icon = initialIcon;
        truck.display = initialDisplay;
        truck.lastcheckIn = checkIn;
      });
      // Sorting the commands with a custom function
      const sortedData = vehicles?.data.sort(dynamicSort('vehicleId'));

      setTrucks(sortedData);
    }
  }, [vehicles?.data]);

  useEffect(() => {
    setVehicles([...selectedTrucks]);
  }, [selectedTrucks, setVehicles]);

  useEffect(() => {
    if (selectedTrucks.length > 0) {
      setNextBtnDisabled(false);
    } else {
      setNextBtnDisabled(true);
    }
  }, [selectedTrucks.length, setNextBtnDisabled]);

  useEffect(() => {
    if (inputValue) {
      let selectedGroup = groups?.data.find(group => group.id === inputValue);

      for (const truck of trucks) {
        if (!selectedGroup?.vehicleDeviceRefs?.includes(String(truck.crn))) {
          truck.display = 'none';
        }
      }
    } else {
      for (const truck of trucks) {
        truck.display = initialDisplay;
      }
    }

    const sortedData = trucks.sort(dynamicSort('vehicleId'));

    setTrucks([...sortedData]);
  }, [inputValue]);

  const handleSelectedVehicle = truckSelected => {
    trucks.forEach(async truck => {
      if (truck.crn === truckSelected.crn) {
        if (!truck.selected) {
          truck.icon = 'cancel';
          truck.selected = true;
          truck.iconColor = 'error';
          setSelectedTrucks([
            ...selectedTrucks,
            { vehicleId: truckSelected.vehicleId, vehicleRef: truckSelected.vehicleRef },
          ]);
        } else {
          truck.icon = 'add_circle';
          truck.selected = false;
          truck.iconColor = 'primary';

          const excludingTruck = selectedTrucks.filter(tr => tr.vehicleRef !== truckSelected.vehicleRef);
          setSelectedTrucks([...excludingTruck]);
        }
      }
    });

    setTrucks([...trucks]);
  };

  return (
    <>
      <Autocomplete
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        inputValue={inputValue}
        id="filter-group"
        isOptionEqualToValue={(option, value) => option.title === value.title}
        options={inputOptions}
        renderInput={params => (
          <TextField {...params} label={<FormattedMessage id={'group'} defaultMessage={'group'} />} />
        )}
        style={{ marginBottom: '15px' }}
      />

      <Core.Spinner spin={vehicles.isLoading || vehicles.isFetching}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={{ xs: 2 }}>
          {trucks &&
            trucks.map(truck => {
              return (
                <Grid item xs={6} style={{ display: truck.display }}>
                  <GMSelectedCard
                    className="command-card"
                    key={truck.crn}
                    contentId={truck.vehicleId}
                    icon={truck.icon}
                    iconStyle={iconStyle}
                    onClick={() => handleSelectedVehicle(truck)}
                    iconColor={truck.iconColor}
                    disabled={truck.disabled}
                    selected={truck.selected}
                    mutedText={
                      <>
                        <FormattedMessage id={'vehicleLastCheckIn'} defaultMessage={'vehicleLastCheckIn'} />
                        {truck.lastcheckIn}
                      </>
                    }
                  />
                </Grid>
              );
            })}
        </Grid>
      </Core.Spinner>
    </>
  );
};
