import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Assurance, Groups, VehicleDevices } from '../api';

// import mockdata from '../views/assurance/utils/mockData';

export const useDeviceCommands = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['useListDeviceCommands', entityRef],
    queryFn: () => Assurance.getDeviceCommands({ entityRef }),
    staleTime: Infinity,
    enabled: true,
    retry: 0,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

export const useListDeviceCommands = entityRef => {
  const query = useQuery({
    queryKey: ['deviceCommands', entityRef],
    queryFn: () => Assurance.getDeviceMessageCommands({ entityRef }),
    staleTime: Infinity,
    enabled: true,
    retry: 0,
    // refetchOnWindowFocus: 'always',
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

export const useListDeviceGroups = entityRef => {
  const query = useQuery({
    queryKey: ['deviceGroups', entityRef],
    queryFn: () => Groups.getDeviceGroups({ entityRef }),
    staleTime: Infinity,
    enabled: true,
    retry: 0,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

export const useListVehicleDevices = entityRef => {
  const query = useQuery({
    queryKey: ['deviceVehiclesList', entityRef],
    queryFn: () => VehicleDevices.getAllVehicleDevices({ entityRef }),
    staleTime: Infinity,
    enabled: true,
    retry: 0,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

/**
 * Function for post the device command
 * @author Juan David Reina
 */
export const useSendDeviceMessage = (data, entityRef) => {
  const queryClient = useQueryClient();
  const queryKey = ['useListDeviceCommands', entityRef];

  const mutation = useMutation({
    mutationFn: data => {
      Assurance.postDeviceMessage({ ...data });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['useListDeviceCommands', entityRef] });
    },
  });

  return mutation;
};
