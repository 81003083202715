import { Auth, User } from 'connex-cds';
import React from 'react';
import { useParams } from 'react-router-dom';
import menuConfig from '../../menu-config';

const { IFrameAuthenticated } = Auth;

const AuthenticatedRoutes = () => {
  const { entityRef } = useParams();
  const UserPermissions = User.usePermissions();

  const newMenuConfig = [...menuConfig];

  if (UserPermissions.isPlatformAdmin || UserPermissions.isPlatformSupport) {
    newMenuConfig.push({
      id: 'insights-settings',
      labelStringId: 'insights-settings',
      path: '/insights-settings',
      icon: 'fa-thin fa-gear',
    });
  }

  return <IFrameAuthenticated entityRef={entityRef} menuConfig={newMenuConfig} />;
};

export default AuthenticatedRoutes;
