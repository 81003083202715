import { Form } from 'connex-cds';
import DynamicComponent from './DynamicComponent';
import * as devicesConfig from '../utils';
import { useGetDeviceConfig } from '../../../query-hooks/devices';
import { useEffect, useState } from 'react';

const DynamicComponentProvider = ({ config, deviceData, setDoVehicleDevicesConfigRefetch }) => {
  const { FormProvider } = Form;
  const device = useGetDeviceConfig(config.deviceConfigRef, config.entityRef);
  const [selectedConfig, setSelectedConfig] = useState(null);

  useEffect(() => {
    let sensorType = '';

    if (device) {
      sensorType = device?.data?.id.replace(/\s/g, '');
    } else {
      sensorType = config.id.replace(/\s/g, '');
    }

    const selectConfig = devicesConfig[sensorType];
    setSelectedConfig(selectConfig);
  }, [config.id, device, selectedConfig]);

  return (
    <>
      {selectedConfig && (
        <FormProvider
          config={selectedConfig}
          deviceConfigData={config}
          data={device?.data ? device?.data : config}
          deviceData={deviceData}
          setDoVehicleDevicesConfigRefetch={setDoVehicleDevicesConfigRefetch}
        >
          <DynamicComponent />
        </FormProvider>
      )}
    </>
  );
};

export default DynamicComponentProvider;
