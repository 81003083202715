import { Form } from 'connex-cds';
import * as Yup from 'yup';
import moment from 'moment';

export default {
  path: 'devices',
  labelStringId: 'devices',
  testId: 'devices',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    crn: Yup.string().required('required'),
    vehicleId: Yup.string().required('required'),
    vehicleRef: Yup.string().required('required'),
    registration: Yup.string().required('required'),
    lastCheckinDateTime: Yup.string().required('required'),
    sensorType: Yup.string().required('required'),
    deviceConfigRefs: Yup.object().required('required'),
  }),

  fields: [
    {
      path: 'vehicleId',
      valuePath: 'vehicleId',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'label',
      labelStringId: 'vehicleId',
      testId: 'vehicleId',
    },
    {
      path: 'lastCheckinDateTime',
      valuePath: 'lastCheckinDateTime',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'label',
      labelStringId: 'lastCheckinDateTime',
      testId: 'lastCheckinDateTime',
      formatter: props => {
        return moment(props.row.lastCheckinDateTime).format('MM-DD-YYYY hh:mm:ss');
      },
    },
    {
      path: 'sensorType',
      valuePath: 'sensorType',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'label',
      labelStringId: 'sensorType',
      testId: 'sensorType',
    },
  ],
};
