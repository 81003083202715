import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Core, Drawer, Form, Modal } from 'connex-cds';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomRow } from './CustomRow';
import { GMButton } from '../../../cds-labs/GMButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DynamicComponentProvider from './DynamicComponentProvider';
import { dynamicSort } from '../../assurance/utils/dynamicSort';
import { Empty } from 'antd';
import { useGetOneVehicleDevices } from '../../../query-hooks/devices';

export const DeviceDrawer = ({ doRefetch }) => {
  const [doVehicleDevicesConfigRefetch, setDoVehicleDevicesConfigRefetch] = useState(true);
  const { closeDrawer } = Drawer.useDrawerContext();
  const { values } = Form.useFormContext();
  const { openModal } = Modal.useModalContext();
  const [truck, setTruck] = useState('');
  const [truckCrn, setTruckCrn] = useState('');
  const [lastCheckinDateTime, setLastCheckinDateTime] = useState('');
  const [sensorType, setSensorType] = useState('');
  const [deviceList, setDeviceList] = useState([]);
  const devicesConfig = [];
  const vehicleDevices = useGetOneVehicleDevices(values?.crn);
  const tableTitles = [
    <FormattedMessage id={'deviceId'} defaultMessage={'deviceId'} />,
    <FormattedMessage id={'deviceDefault'} defaultMessage={'deviceDefault'} />,
    <FormattedMessage id={'deviceType'} defaultMessage={'deviceType'} />,
  ];

  useEffect(() => {
    if (values) {
      setTruck(values?.vehicleId);
      setLastCheckinDateTime(values?.lastCheckinDateTime);
      setSensorType(values?.sensorType);
      setTruckCrn(values?.crn);
    }
  }, [values]);

  useEffect(() => {
    if (vehicleDevices?.data?.configurations) {
      for (const [key, val] of Object.entries(vehicleDevices?.data?.configurations)) {
        devicesConfig.push({ device: key, devRef: val });
      }

      // Sorting the commands with a custom function
      const sortedData = devicesConfig.sort(dynamicSort('device'));

      setDeviceList([...sortedData]);
    }
  }, [vehicleDevices?.data, values.crn]);

  const openConfigModal = useCallback(
    data => {
      openModal({
        titleStringId: 'updateConfigModal',
        component: (
          <DynamicComponentProvider
            config={data}
            deviceData={values}
            setDoVehicleDevicesConfigRefetch={setDoVehicleDevicesConfigRefetch}
          />
        ),
        width: '800px',
      });
    },
    [openModal]
  );

  return (
    <>
      <Box style={{ marginTop: '20px', width: '100%', height: '100vh' }}>
        <Divider textAlign="left">
          <FormattedMessage id={'truckInformation'} defaultMessage={'truckInformation'} />
        </Divider>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ margin: '20px auto' }}>
          <Grid item xs={4}>
            <TextField
              id="truck"
              label={<FormattedMessage id={'vehicleId'} defaultMessage={'vehicleId'} />}
              variant="outlined"
              value={truck}
              disabled
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="lastCheckinDateTime"
              label={<FormattedMessage id={'lastCheckinDateTime'} defaultMessage={'lastCheckinDateTime'} />}
              variant="outlined"
              value={moment(lastCheckinDateTime).format('MM-DD-YYYY hh:mm:ss')}
              disabled
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="sensorType"
              label={<FormattedMessage id={'sensorType'} defaultMessage={'sensorType'} />}
              variant="outlined"
              value={sensorType}
              disabled
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>
        <Divider textAlign="left" style={{ marginTop: '50px' }}>
          <FormattedMessage id={'truckDevices'} defaultMessage={'truckDevices'} />
        </Divider>
        {vehicleDevices?.isFetching && <Core.Spinner spin={vehicleDevices?.isFetching} />}
        {!vehicleDevices?.isFetching && (
          <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
            {!vehicleDevices?.isFetching && deviceList && deviceList.length <= 0 && (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {deviceList.length > 0 &&
              deviceList.map(row => {
                return (
                  <Accordion key={row.devRef[0].crn} style={{ cursor: 'pointer' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{row?.device}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead style={{ backgroundColor: '#f3f3f3' }}>
                            <TableRow key={row.devRef[0].crn}>
                              {tableTitles &&
                                tableTitles.map(title => {
                                  return (
                                    <TableCell key={Math.floor(Math.random() * 10000)} style={{ fontWeight: '800' }}>
                                      {title}
                                    </TableCell>
                                  );
                                })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row?.devRef.length > 0 &&
                              row?.devRef.map(configRef => {
                                return (
                                  <CustomRow
                                    key={configRef.crn}
                                    data={configRef}
                                    onClick={() => openConfigModal(configRef)}
                                  />
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </div>
        )}
      </Box>
      <Box style={{ position: 'absolute', bottom: '10px', backgroundColor: '#FFF', width: '100%', display: 'flex' }}>
        <GMButton id={'cancelBtn'} type="secondary" onClick={closeDrawer} />
      </Box>
    </>
  );
};
