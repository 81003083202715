import { http } from 'connex-cds';

export default {
  getDeviceConfig: ({ entityRef, crn }) =>
    http.get({ apiName: 'assurance', path: `/${entityRef}/device-configs/${crn}` }),
  postDeviceConfig: ({ entityRef, crn, data }) =>
    http.post({ apiName: 'assurance', path: `/${entityRef}/device-configs`, data }),
  patchDeviceConfig: ({ entityRef, crn, data }) =>
    http.patch({ apiName: 'assurance', path: `/${entityRef}/device-configs/${crn}`, data }),
};
