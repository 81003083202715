import { Core, Form, Modal } from 'connex-cds';
import { camelCase, toUpper } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Box, FormControlLabel, FormGroup, Grid, Switch, TextField } from '@mui/material';
import { Divider } from 'antd';
import { GMButton } from '../../../cds-labs/GMButton';
import { FormattedMessage } from 'react-intl';
import { useAddNewDeviceConfig, useUpdateDeviceConfig } from '../../../query-hooks/devices';
import { useParams } from 'react-router';
import { postNewDeviceConfig } from '../utils/axiosRequests';

const DynamicComponent = () => {
  const { entityRef } = useParams();
  const { closeModal } = Modal.useModalContext();
  const addNewDeviceConfig = useAddNewDeviceConfig();
  const updateDeviceConfig = useUpdateDeviceConfig();
  const { Components, values, data, deviceData, setDoVehicleDevicesConfigRefetch, deviceConfigData, ...formContext } =
    Form.useFormContext();
  const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);
  const [configValues, setConfigValues] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [idConfig, setIdConfig] = useState();
  const [deviceType, setDeviceType] = useState();
  const [configCrn, setConfigCrn] = useState();
  const [isNewConfig, setIsNewConfig] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (deviceConfigData?.entityRef !== entityRef) {
      setIsNewConfig(true);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setIdConfig(data?.id);
      setDeviceType(data?.deviceType);
      setConfigCrn(data?.crn);
      setConfigValues([...data?.configuration]);
    }
  }, [data]);

  const handleChange = event => {
    let val = '';
    const itemChecked = event.target?.checked ? event.target.checked : '';
    const itemValue = event.target?.value ? event.target?.value : '';
    const itemName = event.target?.name ? event.target?.name : event.target?.id;

    if (itemChecked && itemChecked === true) {
      val = 'true';
    } else if (itemChecked && itemChecked === false) {
      val = 'false';
    } else if (itemValue === 'on') {
      val = 'false';
    } else {
      val = itemValue;
    }

    let newData = configValues.filter(el => el.key !== itemName);

    setConfigValues([...newData, { value: val, key: itemName }]);

    setHasChanged(true);
  };

  const saveConfig = useCallback(async () => {
    setIsSaving(true);
    const notNullData = [];

    for (const item of configValues) {
      if (item.value) {
        notNullData.push(item);
      }
    }

    let dataToStore = {
      id: idConfig,
      default: false,
      deviceType: deviceType,
      configuration: notNullData,
    };

    if (isNewConfig) {
      addNewDeviceConfig.mutate({
        entityRef: entityRef,
        crn: configCrn,
        data: { ...dataToStore },
      });

      await postNewDeviceConfig(entityRef, dataToStore, deviceData);
    } else {
      updateDeviceConfig.mutate({
        entityRef: entityRef,
        crn: configCrn,
        data: { configuration: notNullData },
      });
    }

    setDoVehicleDevicesConfigRefetch(true);

    closeModal();
  }, [configValues]);

  const renderComponent = item => {
    const componentName = pascalCase(item.path);
    const componentValue = configValues.find(({ key }) => key === item.path);

    if (item?.dataType === 'boolean') {
      return (
        <FormControlLabel
          label={<FormattedMessage id={componentName} defaultMessage={componentName} />}
          labelPlacement="top"
          style={{ width: '100%' }}
          key={componentName}
          control={
            <Switch
              size="small"
              name={item.path}
              checked={componentValue?.value === 'true' ? true : false}
              onChange={handleChange}
            />
          }
        />
      );
    }

    return (
      <TextField
        key={componentName}
        onChange={handleChange}
        label={<FormattedMessage id={componentName} defaultMessage={componentName} />}
        variant="outlined"
        value={componentValue?.value ? componentValue?.value : ''}
        style={{ margin: '10px auto', width: componentName === 'JsonParams' ? '95%' : '90%' }}
        id={item.path}
        multiline={componentName === 'JsonParams' ? true : false}
        rows={componentName === 'JsonParams' ? 10 : 1}
      />
    );
  };

  return (
    <>
      <Box sx={{ width: '100%', textAlign: 'center', maxHeight: '500px', overflowY: 'auto' }}>
        {isSaving && (
          <Box>
            <Core.Spinner spin={isSaving}></Core.Spinner>
          </Box>
        )}
        {!isSaving && (
          <FormGroup>
            {configValues && (
              <Grid container style={{ margin: '0px auto', width: '100%' }}>
                {formContext?.config?.fields?.map?.(field => {
                  const componentName = pascalCase(field.path);
                  if (componentName === 'JsonParams') {
                    return (
                      <Grid item xs={12} key={field?.path}>
                        {renderComponent(field)}
                      </Grid>
                    );
                  }
                  return (
                    <Grid item xs={6} key={field?.path}>
                      {renderComponent(field)}
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </FormGroup>
        )}
      </Box>
      <Box>
        <Divider></Divider>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <GMButton id="cancelBtn" type="secondary" onClick={closeModal} />
          <GMButton id="save" type="primary" onClick={saveConfig} disabled={!hasChanged} />
        </Box>
      </Box>
    </>
  );
};

export default DynamicComponent;
