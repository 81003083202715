import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { API, ConnexDesignSystem } from 'connex-cds';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { InitializeApi } from './api/InitializeApi';
import messages from './i18n';
import AuthenticatedRoutes from './views/authenticated-routes/AuthenticatedRoutes';
import { RedirectToEntity } from './views/RedirectToEntity';
import { Assurance } from './views/assurance/Assurance';
import { Groups } from './views/groups/Groups';
import { Devices } from './views/devices/Devices';
import { Insights } from './views/insights/Insights';
import { InsightsSettings } from './views/insights-settings/InsightsSettings';

API.setSource('assurance');

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000 } },
});

const App = () => {
  return (
    <InitializeApi>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ConnexDesignSystem messages={messages} appId="assurance">
            <Routes>
              <Route element={<AuthenticatedRoutes />}>
                <Route path="" element={<RedirectToEntity />}>
                  <Route path=":entityRef">
                    <Route path="assurance/*" element={<Assurance />} />
                    <Route path="groups/*" element={<Groups />} />
                    <Route path="devices/*" element={<Devices />} />
                    <Route path="insights/*" element={<Insights />} />
                    <Route path="insights-settings/*" element={<InsightsSettings />} />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </ConnexDesignSystem>
        </QueryClientProvider>
      </BrowserRouter>
    </InitializeApi>
  );
};

export default App;
