import common from '../common';

export default {
  ...common,
  // --- General
  null: '',
  required: 'Required',
  back: 'Back',
  showAll: 'Show All',
  hideAll: 'Hide All',
  save: 'Save',
  undoChanges: 'Undo Changes',
  resetColumns: 'Reset Columns',
  columnFilters: 'Columns Filters',
  displayRowCount: 'Display Row Count',
  visible: 'Visible',
  hidden: 'Hidden',
  closeBtn: 'Close',
  cancelBtn: 'Cancel',
  ErrorMessageTitle: 'Something went wrong!',
  contactAdministrator: 'Contact the administrator for more details.',
  confirmAction: 'Yes',
  denyAction: 'No',

  // --- Assurance
  assurance: 'Assurance',
  // Main table
  gridPreferences: 'Table Preferences',
  deviceCommandsListHeader: 'Device Commands',
  DeviceMessage: 'Device Message',
  crn: 'CRN',
  vehicleId: 'Truck',
  commandText: 'Command',
  addDateTime: 'Created',
  sentDateTime: 'Sent',
  ackDateTime: 'Responded',
  status: 'Status',
  deviceCommandsErrorMessage:
    'An error occurred while trying to retrieve device commands from the server. Contact the administrator for more details.',
  // Modals
  deviceCommandsEditor: 'Command Sent',
  newMessageCommandBtn: 'New Message Command',
  addCommandTitle: 'Add Command',
  editCommandTitle: 'Edit Command',
  selectTruck: 'Select Truck',
  selectATruckTitle: 'Select a Truck',
  commandSentTitle: 'Command Sent',
  send: 'Send',
  done: 'Done',
  addAnotherCommand: 'Add Another Command',
  commandsErrorMessage:
    'An error occurred while trying to retrieve commands list from the server. Contact the administrator for more details.',
  group: 'Group',
  vehicleLastCheckIn: 'Last check in ',
  trucksCount: 'Trucks selected: ',
  commandSent: 'Command Sent!',
  sendCommandError: 'Error sending the Message',
  // --- Groups
  groups: 'Groups',
  // Insights
  insights: 'Insights',
  insightsWorkbooksEditor: 'Insights Workbook Editor',
  groupName: 'Group Name',
  name: 'Name',
  reportName: 'Report Name',
  collectionName: 'Collection Name',
  icon: 'Icon',
  //insights-settings
  'insights-settings': 'Insights Settings',
  showToolbar: 'Show Toolbar',
  showTooltip: 'Show Tooltip',
  showTabs: 'Show Tabs',
  // Main table
  id: 'ID',
  groupIdName: 'Group ID / Name',
  vehicleDeviceRefs: 'Total vehicles in Group',
  // Modal
  newGroupBtn: 'New Group',
  deviceGroupsEditor: 'Create/Edit Group',
  deleteGroupBtn: 'Delete Group',
  deleteGroupTitle: 'Are you sure delete this group?',
  // --- Devices
  devices: 'Devices',
  // Main Table
  registration: 'Registration',
  lastCheckinDateTime: 'Last Check In',
  sensorType: 'Sensor Type',
  deviceConfigRefs: 'Quantity of Devices',
  // Drawer Editor
  devicesEditor: 'Devices Editor',
  truckInformation: 'Truck Information',
  truckDevices: 'Truck Devices',
  deviceId: 'Device ID',
  deviceDefault: 'Config Type',
  deviceType: 'Device Type',
  // Modal Add new Device
  newDeviceBtn: 'Set a new Device',
  addDeviceModalTitle: 'Add new device config to Vehicle',
  // Update Modal
  updateConfigModal: 'Edit Device Config',

  // All device config labels
  MasterUnlockCode: 'Master Unlock Code',
  UnlockCode: 'Unlock Code',
  SelfDiagnosticsUnlockCode: 'Self Diagnostics Unlock Code',
  AWSAccessKeyId: 'AWS Access Key Id',
  AWSSecretAccessKey: 'AWS Secret Access Key',
  AWSUrlAccess: 'AWS Url Access',
  isSlumpFilterEnable: 'Is Slump Filter Enable',
  pressureBufferSize: 'Pressure Buffer Size',
  pressureAgeTurnLimit: 'Pressure Age Turn Limit',
  pressureBufferAcceptanceSlumpRange: 'Pressure Buffer Acceptance Slump Range',
  maximumAverageRPMLimit: 'Maximum Average RPM Limit',
  slumpIncreaseAlertThreshold: 'Slump Increase Alert Threshold',
  JSONParams: 'JSON Params',
  Firmware: 'Firmware',
  SensorSerialNumber: 'Sensor Serial Number',
  AcquisitionScriptVersion: 'Acquisition Script Version',
  AirComputationCalibrationModelVersion: 'Air Computation Calibration Model Version',
  PAUMacAddress: 'PAU Mac Address',
  isValidationEnable: 'Is Validation Enable',
  isDrumSpeedEnable: 'Is Drum Speed Enable',
  isPatternEnable: 'Is Pattern Enable',
  isEntryExitEnable: 'Is Entry Exit Enable',
  isCorrelationEnable: 'Is Correlation Enable',
  isSaturationEnable: 'Is Saturation Enable',
  isPressureRangeEnable: 'Is Pressure Range Enable',
  drumSpeedMargin: 'Drum Speed Margin',
  maxUPressureRange: 'Max. U Pressure Range',
  maxRSPressureRange: 'Max. RS Pressure Range',
  rightSlopePressureCoefficient: 'Right Slope Pressure Coefficient',
  leftSLopePressureCoefficient: 'Left S Lope Pressure Coefficient',
  wShapePressureCoefficient: 'W Shape Pressure Coefficient',
  vShapePressureCoefficient: 'V Shape Pressure Coefficient',
  coefficientOfCorrelation: 'Coefficient Of Correlation',
  Version: 'Version',
  Priority: 'Priority',
  Critical: 'Critical',
  IsStatusChangeEnable: 'Is Status Change Enable',
  CoefFluid: 'Coef. Fluid',
  CoefSlope: 'Coef. Slope',
  CoefStiff: 'Coef. Stiff',
  LimitFluid: 'Limit Fluid',
  LimitStiff: 'Limit Stiff',
  LimitSlope: 'Limit Slope',
  MinimumDistanceValid: 'Minimum Distance Valid',
  MinimumSlopeValid: 'Minimum Slope Valid',
  MinTurnsReaction: 'min Turns Reaction',
  MinTargetSlumpReaction: 'min Target Slump Reaction',
  MinimumTurnsStiffAfterLoaded: 'Minimum Turns Stiff After Loaded',
  MinimumTurnsFluidAfterLoaded: 'Minimum Turns Fluid After Loaded',
  MinimumTurnsStiffAfterLoadedWB: 'Minimum Turns Stiff After Loaded WB',
  MinimumTurnsStiffAfterLoadedWb: 'Minimum Turns Stiff After Loaded WB',
  MinimumTurnsFluidAfterLoadedWB: 'Minimum Turns Fluid After Loaded WB',
  MinimumTurnsFluidAfterLoadedWb: 'Minimum Turns Fluid After Loaded WB',
  MinimumTurnsStiffAfterPMax: 'Minimum Turns Stiff After P Max',
  MinimumTurnsFluidAfterPMAX: 'Minimum Turns Fluid After P MAX',
  MinimumTurnsFluidAfterPmax: 'Minimum Turns Fluid After P MAX',
  MaximumTurnsAfterLoaded: 'Maximum Turns After Loaded',
  MaximumTurnsAfterLoadedWB: 'Maximum Turns After Loaded WB',
  MaximumTurnsAfterLoadedW: 'Maximum Turns After Loaded WB',
  AwsAccessKeyId: 'Aws Access Key Id',
  AwsSecretAccessKey: 'Aws Secret Access Key',
  AwsUrlAccess: 'Aws Url Access',
  IsSlumpFilterEnable: 'Is Slump Filter Enable',
  PressureBufferSize: 'Pressure Buffer Size',
  PressureAgeTurnLimit: 'Pressure Age Turn Limit',
  PressureBufferAcceptanceSlumpRange: 'Pressure Buffer Acceptance Slump Range',
  MaximumAverageRpmLimit: 'Maximum Average Rpm Limit',
  SlumpIncreaseAlertThreshold: 'Slump Increase Alert Threshold',
  IsValidationEnable: 'Is Validation Enable',
  IsDrumSpeedEnable: 'Is Drum Speed Enable',
  IsPatternEnable: 'Is Pattern Enable',
  IsEntryExitEnable: 'Is Entry Exit Enable',
  IsCorrelationEnable: 'Is Correlation Enable',
  IsSaturationEnable: 'Is Saturation Enable',
  IsPressureRangeEnable: 'Is Pressure Range Enable',
  DrumSpeedMargin: 'Drum Speed Margin',
  MaxUPressureRange: 'Max U Pressure Range',
  MaxRsPressureRange: 'Max Rs Pressure Range',
  RightSlopePressureCoefficient: 'Right Slope Pressure Coefficient',
  LeftSLopePressureCoefficient: 'LeftS Lope Pressure Coefficient',
  WShapePressureCoefficient: 'W Shape Pressure Coefficient',
  VShapePressureCoefficient: 'V Shape Pressure Coefficient',
  CoefficientOfCorrelation: 'Coefficient Of Correlation',
  JsonParams: 'Json Params',
  FirmwareVersion: 'Firmware Version',
  PauMacAddress: 'Pau Mac Address',
};
