import { Box, Grid, TextField, Typography } from '@mui/material';
import { Divider, Modal, Space } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { GMButton } from '../../../cds-labs/GMButton';
import { Core, Form, Modal as cdsModal } from 'connex-cds';
import { useListVehicleDevices } from '../../../query-hooks/assurance';
import { useCallback, useEffect, useState } from 'react';
import { GMSelectedCard } from '../../../cds-labs/GMSelectedCard';
import { dynamicSort } from '../../assurance/utils/dynamicSort';
import { useParams } from 'react-router';
import { useDeleteGroup, useUpdateGroup } from '../../../query-hooks/groups';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { WarningFilled } from '@ant-design/icons';
const { confirm } = Modal;

export const EditGroup = () => {
  const { entityRef } = useParams();
  const vehicles = useListVehicleDevices(entityRef);
  const { closeModal } = cdsModal.useModalContext();
  const { mutate, isSuccess } = useUpdateGroup();
  const { values } = Form.useFormContext();
  const [trucks, setTrucks] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [selectedTrucks, setSelectedTrucks] = useState([]);
  const [doRefetch, setdoRefetch] = useState(true);
  const initialIcon = 'add_circle';
  const initialDisplay = 'block';
  const iconStyle = {
    fontSize: '50px',
  };
  const intl = useIntl();
  const deleteGroup = useDeleteGroup();

  useEffect(() => {
    if (doRefetch) {
      vehicles.refetch();
      setdoRefetch(false);
    }
  }, [doRefetch, vehicles]);

  useEffect(() => {
    const tmpSelectedTrucks = [];

    setGroupName(values?.id);

    if (vehicles?.data && vehicles?.data.length > 0) {
      vehicles?.data.forEach(truck => {
        truck.display = initialDisplay;
        if (values.vehicleDeviceRefs.includes(truck.vehicleRef)) {
          truck.icon = 'cancel';
          truck.selected = true;
          truck.iconColor = 'error';
          tmpSelectedTrucks.push({ vehicleId: truck.vehicleId, vehicleRef: truck.vehicleRef });
        } else {
          truck.icon = initialIcon;
        }
      });

      setSelectedTrucks([...tmpSelectedTrucks]);

      // Sorting the commands with a custom function
      const sortedData = vehicles?.data.sort(dynamicSort('vehicleId'));

      setTrucks(sortedData);
    }
  }, [vehicles?.data]);

  useEffect(() => {
    if (isSaving) {
      if (isSuccess) {
        setInterval(() => {
          setIsSaving(false);
        }, 2000);
        // closeModal();
      }
    }
  }, [ isSaving, isSuccess]);

  const handleSelectedVehicle = truckSelected => {
    trucks.forEach(async truck => {
      if (truck.vehicleRef === truckSelected.vehicleRef) {
        if (!truck.selected) {
          truck.icon = 'cancel';
          truck.selected = true;
          truck.iconColor = 'error';
          setSelectedTrucks([
            ...selectedTrucks,
            { vehicleId: truckSelected.vehicleId, vehicleRef: truckSelected.vehicleRef },
          ]);
        } else {
          truck.icon = 'add_circle';
          truck.selected = false;
          truck.iconColor = 'primary';

          const excludingTruck = selectedTrucks.filter(tr => tr?.vehicleRef !== truckSelected?.vehicleRef);
          setSelectedTrucks([...excludingTruck]);
        }
      }
    });

    setTrucks([...trucks]);
  };

  const handleChange = newValue => {
    setGroupName(newValue.target.value);
  };

  const saveGroup = () => {
    setIsSaving(true);
    const vehicleDeviceRefs = [];

    selectedTrucks.forEach(truck => {
      vehicleDeviceRefs.push(truck?.vehicleRef);
    });

    mutate({
      entityRef: entityRef,
      crn: values?.crn,
      data: {
        id: groupName,
        vehicleDeviceRefs,
      },
    });

    closeModal();
  };

  const showDeleteConfirm = useCallback(async () => {
    confirm({
      title: intl.formatMessage({ id: 'deleteGroupTitle' }),
      icon: <WarningFilled />,
      okText: intl.formatMessage({ id: 'confirmAction' }),
      okType: 'danger',
      cancelText: intl.formatMessage({ id: 'denyAction' }),
      onOk() {
        confirmDelete();
      },
      onCancel() {
        return;
      },
    });
  }, []);

  const confirmDelete = useCallback(() => {
    deleteGroup.mutate({
      crn: values?.crn,
      entityRef,
    });

    closeModal();
  }, []);

  return (
    <>
      {isSaving && (
        <Box>
          <Core.Spinner spin={isSaving}></Core.Spinner>
        </Box>
      )}
      {!isSaving && (
        <Box>
          <TextField
            id="outlined-basic"
            label={<FormattedMessage id={'id'} defaultMessage={'Name'} />}
            variant="outlined"
            value={groupName}
            onChange={handleChange.bind(this)}
            style={{ width: '100%', marginBottom: '20px' }}
          />
          <Box style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Core.Spinner spin={vehicles?.isLoading || vehicles?.isFetching}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={{ xs: 2 }}>
                {trucks &&
                  trucks.map(truck => {
                    return (
                      <Grid item xs={6} style={{ display: truck.display }}>
                        <GMSelectedCard
                          className="command-card"
                          key={truck.crn}
                          contentId={truck.vehicleId}
                          icon={truck.icon}
                          iconStyle={iconStyle}
                          onClick={() => handleSelectedVehicle(truck)}
                          iconColor={truck.iconColor}
                          disabled={truck.disabled}
                          selected={truck.selected}
                          mutedText={
                            <>
                              <FormattedMessage id={'vehicleLastCheckIn'} defaultMessage={'vehicleLastCheckIn'} />
                              {truck.lastcheckIn}
                            </>
                          }
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Core.Spinner>
          </Box>
        </Box>
      )}
      <Divider></Divider>
      <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
          <Typography variant="subtitle1" style={{ marginRight: '50px' }}>
            <FormattedMessage id={'trucksCount'} defaultMessage={'trucksCount'} /> {selectedTrucks.length}
          </Typography>
          <GMButton
            startIcon={<DeleteTwoToneIcon />}
            id="deleteGroupBtn"
            variant="outlined"
            type="error"
            onClick={() => showDeleteConfirm()}
          />
        </Box>
        <Box style={{ display: 'flex' }}>
          <GMButton id="cancelBtn" type="secondary" onClick={closeModal} />
          <GMButton
            id="save"
            type="primary"
            onClick={saveGroup}
            disabled={groupName === '' || selectedTrucks.length <= 0}
          />
        </Box>
      </Space>
    </>
  );
};
